/**
 * **File: App.tsx**
 *
 * This file defines the core structure of the application. It includes:
 * * **Routing:** Handles navigation using React Router.
 * * **Authentication:** Manages user login/logout and redirects unauthenticated users.
 * * **Layout:** Provides the common AdminPortalLayout for authenticated views.
 * * **Syncing:**  Displays a loader overlay during data synchronization.
 *
 * **Key Dependencies:**
 * * `react-router-dom`
 * * `@digitalworkflow/dwreactcommon`
 * * `@digitalworkflow/dwloginclient`
 * * Custom context (`SyncContext`, `AuthContext`)
 */

import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AdminPortalLayout, MenuItem, ProfileMenuItem } from '@digitalworkflow/dwreactcommon'
import '@digitalworkflow/dwreactcommon/dist/index.css'
import logoImage from './assets/images/bgis-logo.png'
import logoSmImage from './assets/images/bgis-logo-sm.png'
import { useAuth } from './context/AuthContext'
import SetToken from './Pages/SetToken'
import { redirectToLogin } from './Utils'
import { adminPortalLayoutRoutes } from './routes'

/**
 * The GuestRouteRedirect component handles redirection for unauthenticated users.
 *
 * 1. Captures the current internal URL.
 * 2. Stores the internal URL in local storage for later redirection upon successful login.
 * 3. Redirects the user to the central login portal with the appropriate guest flag and return URL information.
 */
const GuestRouteRedirect = () => {
  redirectToLogin()

  return null
}

/**
 * Array of menu items to be displayed in the profile dropdown.
 */
export const profileMenuItems: Array<ProfileMenuItem> = [
  {
    type: 'Action',
    text_en: 'Logout',
    icon: 'fa fa-power-off',
    action: () => redirectToLogin()
  }
]

/**
 * Array of menu items for the main navigation.
 */
const menuItems: Array<MenuItem> = [
  {
    item_type: 'Link',
    title_en: 'Translations',
    tooltip_en: 'Display the main translations page',
    icon: 'fas fa-home',
    route: '/translations',
    view_groups: 'Everyone'
  }
  // {
  //   item_type: 'Menu',
  //   title_en: 'Components',
  //   icon: 'fas fa-utensils',
  //   view_groups: 'PI, PSI',
  //   children: [
  //     {
  //       item_type: 'Link',
  //       subtitle_en: 'WorldClock',
  //       tooltip_en: 'Display WorldClock',
  //       icon: 'fas fa-clock',
  //       route: '/clock',
  //       badge_count: 10,
  //       view_groups: '"PI, PSI'
  //     },
  //     {
  //       item_type: 'Link',
  //       subtitle_en: 'Notepad',
  //       tooltip_en: 'Display Notepad',
  //       icon: 'fas fa-notebook',
  //       route: '/notepad',
  //       badge_count: null,
  //       view_groups: '"PI, PSI'
  //     }
  //   ]
  // }
]

/**
 * The main App component.
 *
 * @returns The JSX structure, including routing, authentication handling, and the AdminPortalLayout.
 */
function App() {
  const { isAuthenticated, user, authTried } = useAuth()

  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route
            path='/'
            element={
              !authTried ? (
                <SetToken />
              ) : isAuthenticated ? (
                <AdminPortalLayout
                  menuItems={menuItems}
                  logoImage={logoImage}
                  logoSmImage={logoSmImage}
                  username={user.username}
                  profileMenuItems={profileMenuItems}
                  showWorldClock={false}
                />
              ) : (
                <GuestRouteRedirect />
              )
            }
          >
            {adminPortalLayoutRoutes.map((route: any, index: number) => (
              <Route key={index} path={route.path} element={<route.element />} />
            ))}
          </Route>
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  )
}

export default App
