import { DockLayout, TabData } from '@digitalworkflow/dwreactcommon'
import React, { RefObject } from 'react'
import TabClose from '../components/TabClose/TabClose'

export class RcDockConfig {
  static getTab(
    id: string,
    tabTitle: string,
    dockLayoutRef: RefObject<DockLayout | null>,
    Content: () => React.JSX.Element,
    height = 440,
    xPosition = 200,
    width = 500,
    yPosition = 10,
    tabId?: string
  ) {
    return {
      id: id,
      tabs: [
        {
          id: tabId ?? id,
          title: (
            <>
              <span>{tabTitle}</span>
              <TabClose dockLayoutRef={dockLayoutRef} id={tabId ?? id} />
            </>
          ),
          content: <Content />,
          group: 'close-all'
        }
      ],
      group: 'close-all',
      x: xPosition,
      y: yPosition,
      w: width,
      h: height
    } as any
  }

  static addFloatBox(dockLayoutRef: RefObject<DockLayout | null>, floatBoxId: string, tab: TabData) {
    if (dockLayoutRef && dockLayoutRef.current) {
      if (!dockLayoutRef.current.find(floatBoxId)) {
        dockLayoutRef.current.dockMove(tab, null, 'float')
      }
    }
  }

  static removeRCTab(dockLayoutRef: RefObject<DockLayout | null>, id: string) {
    if (dockLayoutRef && dockLayoutRef.current) {
      const _maximized: any = dockLayoutRef.current.find(id)
      if (_maximized) {
        dockLayoutRef.current.dockMove(_maximized as any, null, 'remove')
      }
    }
  }

  static updateRcTab(dockLayoutRef: RefObject<DockLayout | null>, id: string, tabData: any) {
    if (dockLayoutRef && dockLayoutRef.current) {
      dockLayoutRef.current.updateTab(id, tabData)
    }
  }
}
