import React, { useState, useEffect, useRef } from 'react'
import '../../styles/Filters.scss'
import { SelectDropdown } from '@digitalworkflow/dwreactcommon'
import { ProjectCollection, ProjectDocumentType, TranslateCollection } from '@digitalworkflow/dwtranslateclient'
import { FilterOption, IKey, Language } from '../../types'
import { defaultSelectStyle, searchkeyStyle, moduleTypeOptions } from '../../constants'
import { RelatedTablesType } from '@digitalworkflow/dwtranslateclient/lib/Models/Translate/TranslateSchema'

interface IFiltersProps {
  handleLanguageChange: (option: FilterOption) => void
  handleKeysReturned: (keys: IKey[]) => void
}

const Filters = ({ handleLanguageChange, handleKeysReturned }: IFiltersProps) => {
  const projects = useRef<Partial<ProjectDocumentType>[]>([])
  const currentProject = useRef<string>('')
  const currentLanguage = useRef<string>('')
  const currentRelatedTables = useRef<RelatedTablesType[]>([])
  const searchFilterRef = useRef<any>(null)
  const projectFilterRef = useRef<any>(null)
  const languageFilterRef = useRef<any>(null)
  const relatedTableFilterRef = useRef<any>(null)
  const isLoading = useRef<boolean>(true)
  const [projectOptions, setProjectOptions] = useState<FilterOption[]>([])
  const [languageOptions, setLanguageOptions] = useState<FilterOption[]>([])
  const [keyOptions, setKeyOptions] = useState<FilterOption[]>([])

  useEffect(() => {
    fetchProjects()
  }, [])

  async function fetchProjects() {
    const fetchedProjects = await ProjectCollection.getAvailableProjectsList()
    const allProjects = Object.values(fetchedProjects).map((project) => project[0]._data)
    const options = allProjects.map((project) => ({
      value: project.project_id ?? '',
      label: project.name_en ?? 'Unknown Project'
    }))
    projects.current = allProjects
    setProjectOptions(options)
    handleProjectChangeLocal(options[0])
    isLoading.current = false
  }

  function handleProjectChangeLocal(option: FilterOption) {
    if (option) {
      clearFilters()

      const selectedProject = projects.current.find((project) => project.project_id === option.value)
      currentProject.current = selectedProject?.project_id ?? ''

      const languages =
        selectedProject?.languages
          ?.filter((language: Language) => language.is_active)
          .map((language: Language) => ({
            value: language.full_code ?? '',
            label: language.language ?? 'Unknown Language'
          })) ?? []
      setLanguageOptions(languages)

      if (isLoading.current) {
        handleLanguageChangeLocal(languages[0])
      } else {
        languageFilterRef.current?.setValue(languages[0])
        relatedTableFilterRef.current?.setValue([])
      }
    }
  }

  function handleLanguageChangeLocal(option: FilterOption) {
    if (option) {
      searchFilterRef.current?.clearValue()
      currentLanguage.current = option.value
      getKeys()
      handleLanguageChange(option)
    }
  }

  function handleRelatedTableChangeLocal(option: FilterOption[]) {
    if (option) {
      searchFilterRef.current?.clearValue()
      currentRelatedTables.current = option.map((opt) => opt.value as RelatedTablesType)
      getKeys()
    }
  }

  async function getKeys(activeKeyId?: string) {
    const keys = await TranslateCollection.findAllKeys(currentProject.current)

    const keyOptions: FilterOption[] = []
    const filteredKeys: IKey[] = []
    const data: IKey[] = keys.map((key) => key.data)

    let keyAlreadyActive = false
    data.forEach((key) => {
      let relatedTableFound = false

      // check if the key is present in selected related table filter
      relatedTableFound =
        !currentRelatedTables.current.length ||
        currentRelatedTables.current.findIndex((t) => t === key.related_table) >= 0

      key.isActive = false
      // check if the activeKeyId is provided and no other key has been activated before current iteration
      if (activeKeyId && activeKeyId.length && key.id === activeKeyId && !keyAlreadyActive) {
        key.isActive = true
        keyAlreadyActive = true
      }
      // check if the activeKeyId is not provided and no other key has been activated before current iteration
      else if ((!activeKeyId || !activeKeyId?.length) && !keyAlreadyActive) {
        const variantFound = key.variants?.findIndex((v) => v.fullCode === currentLanguage.current)

        // check if the variant was found according to selected language filter also key is in current related table
        if (relatedTableFound && variantFound !== undefined && variantFound !== -1) {
          keyAlreadyActive = true
          key.isActive = true
        }
      }

      // if key belongs to current related table filter, pass its variant as option for search filter
      if (relatedTableFound) {
        key.variants?.forEach(
          (variant) =>
            variant.fullCode === currentLanguage.current &&
            keyOptions.push({ label: variant.text, value: key?.id ?? '' })
        )
        filteredKeys.push(key)
      }
    })

    setKeyOptions(keyOptions)
    handleKeysReturned(filteredKeys)
  }

  function handleKeySelectLocal(option: FilterOption) {
    if (option) {
      getKeys(option.value)
    }
  }

  function clearFilters() {
    searchFilterRef.current?.clearValue()
    projectFilterRef.current?.clearValue()
    languageFilterRef.current?.clearValue()
    relatedTableFilterRef.current?.clearValue()
    currentProject.current = ''
    currentLanguage.current = ''
    currentRelatedTables.current = []
  }

  return (
    <div>
      <div className='page-title'>TRANSLATIONS</div>
      <div>
        {!isLoading.current && (
          <div className='search-key'>
            <span>
              <i className='fa-regular fa-search' />
            </span>
            <SelectDropdown
              ref={searchFilterRef}
              options={keyOptions}
              menuPortalTarget={document.body}
              placeholder='Search Key'
              isClearable
              style={{ ...defaultSelectStyle, ...searchkeyStyle }}
              onChange={(option: any) => handleKeySelectLocal(option)}
            />
          </div>
        )}
      </div>
      <div className='filter-container'>
        <div className='filter-select'>
          {!isLoading.current && (
            <SelectDropdown
              ref={projectFilterRef}
              options={projectOptions}
              menuPortalTarget={document.body}
              onChange={(option: any) => handleProjectChangeLocal(option)}
              placeholder='All Projects'
              style={defaultSelectStyle}
              defaultValue={projectOptions[0]}
            />
          )}
        </div>
        <div className='filter-select'>
          {!isLoading.current && (
            <SelectDropdown
              ref={languageFilterRef}
              options={languageOptions}
              menuPortalTarget={document.body}
              onChange={(option: any) => handleLanguageChangeLocal(option)}
              placeholder='All Languages'
              style={defaultSelectStyle}
              defaultValue={languageOptions[0]}
            />
          )}
        </div>
        <div>
          <SelectDropdown
            ref={relatedTableFilterRef}
            options={moduleTypeOptions}
            menuPortalTarget={document.body}
            placeholder='Filter By'
            style={defaultSelectStyle}
            isMulti
            isClearable
            closeMenuOnSelect={false}
            onChange={(option: any) => handleRelatedTableChangeLocal(option)}
            noOptionsMessage={() => null}
          />
        </div>
      </div>
    </div>
  )
}

export default Filters
