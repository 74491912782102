import React, { useCallback, useEffect, useState } from 'react'
import KeyListItem from './KeyListItem/KeyListItem'
import { toggleIsActiveForArray } from '../../../Utils'
import '../../../styles/KeyList.scss'
import { IKey } from '../../../types'

type IKeyList = {
  keys: IKey[]
  selectedLanguageFullCode: string
  handleKeySelect: (id: string) => void
}

const KeyList = ({ keys, selectedLanguageFullCode, handleKeySelect }: IKeyList) => {
  const [data, setData] = useState<Partial<IKey>[]>([])

  const handleClick = useCallback(
    (id: number) => {
      const newData = toggleIsActiveForArray<Partial<IKey>>(data, id)
      const activeKeyId = newData.find((data) => data.isActive)?.id
      activeKeyId && handleKeySelect(activeKeyId)
      setData([...newData])
    },
    [data]
  )

  useEffect(() => {
    setData(keys)
  }, [keys])

  return data.length ? (
    <div className='list-wrapper'>
      {data?.map((d, index) => {
        const currentVariant = d.variants?.findIndex((v) => v.fullCode.toLowerCase() === selectedLanguageFullCode)
        return currentVariant !== undefined && currentVariant !== -1 ? (
          <KeyListItem
            key={index}
            id={index}
            isActive={d.isActive}
            text={d.variants?.[currentVariant ?? 0].text ?? ''}
            onClick={handleClick}
          />
        ) : null
      })}
    </div>
  ) : (
    <div className='no-keys'>
      <span>No keys found</span>
    </div>
  )
}
export default KeyList
