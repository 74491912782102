import React from 'react'
import { Modal, Progress } from 'reactstrap'

interface Props {
  width: number
  isOpen: boolean
}

const SyncModal = ({ width, isOpen }: Props) => {
  return (
    <div>
      <Modal centered isOpen={isOpen} size='lg' className='sync-modal'>
        <div className='modal-body'>
          <h2 className='text-center mt-5' id='myModalLabel'>
            Syncing Data...
          </h2>
          <h6 className='text-center mb-4'>Please wait while your account is synced</h6>
          <div>
            <Progress value={width} />
            <h6 className='text-center mt-3'>{width}%</h6>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default SyncModal
