/**
 * Entry point for the React application.
 * Responsibilities:
 * - Import necessary modules and components.
 * - Set up authentication and synchronization contexts.
 * - Initialize global configuration.
 * - Render the root App component into the DOM.
 */

import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { AuthProvider } from './context/AuthContext'
import { SyncProvider } from './context/SyncContext'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.scss'
import { AppConfig } from './config/AppConfig'

AppConfig.setAuthServiceInstance()

/**
 * Root element of the React application, nested within Auth and Sync providers.
 * Uses React StrictMode for potential issue identification in development.
 */
const app = (
  <AuthProvider>
    <SyncProvider>
      <App />
    </SyncProvider>
  </AuthProvider>
)

/**
 * Retrieves the DOM element with id 'root' as the target for rendering.
 */
const rootElement = document.getElementById('root')

/**
 * Handles DOM rendering, or logs an error if the 'root' element is not found.
 */
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement)
  root.render(app)
} else {
  console.error('Error: No "root" element found')
}

// Performance measurement (can be customized for logging or analytics)
reportWebVitals()
