import React, { useMemo, useRef } from 'react'
import '../../styles/TranslationContent.scss'
import TranslatePortalGrid from '../TranslatePortalGrid/TranslatePortalGrid'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { TranslationVariant } from '../../types'

interface TranslationContentProps {
  handleEditTranslation: (content: TranslationVariant) => void
  handleRevision: (content: TranslationVariant) => void
  TranslationContentList: TranslationVariant[]
}

const defaultColDef = {
  sortable: true,
  filter: 'agTextColumnFilter',
  floatingFilter: true,
  minWidth: 140,
  resizable: true,
  cellStyle: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    lineHeight: '1.5'
  }
}

const TranslationContent = ({
  handleEditTranslation,
  handleRevision,
  TranslationContentList
}: TranslationContentProps) => {
  const gridRef = useRef<any>(null)

  const columnDefs = useMemo((): ColDef[] => {
    return [
      {
        headerName: 'Languages',
        // field: 'fullCode',
        valueGetter: (row) => row.data.fullCode + ' v.' + row.data.version,
        flex: 1
      },
      {
        headerName: 'Translation',
        field: 'text',
        flex: 7,
        wrapText: true,
        autoHeight: true
      },
      {
        headerName: 'Action',
        width: 140,
        sortable: false,
        floatingFilter: false,
        cellRenderer: (params: ICellRendererParams) => (
          <div className='action-buttons'>
            <div className='edit-btn' onClick={() => handleEditTranslation(params.data)}>
              Edit
            </div>
            <div className='vertial-bar' />
            <div className='revisions-btn' onClick={() => handleRevision(params.data)}>
              Revisions
            </div>
          </div>
        )
      }
    ] as ColDef[]
  }, [gridRef.current, handleEditTranslation])

  function NoContentRenderer() {
    return (
      <div className='no-content-wrapper'>
        <span className='no-content-header'>No Key Selected</span>
        <span className='no-content-span'>Please select a key to view its translations</span>
      </div>
    )
  }

  return (
    <div className='translation-content-container'>
      <div className='key-name'>
        Welcome to translate portal. Here you can manage translations of all projects under digital workflow.
      </div>
      {TranslationContentList.length ? (
        <TranslatePortalGrid
          gridRef={gridRef}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowData={TranslationContentList}
        />
      ) : (
        <NoContentRenderer />
      )}
    </div>
  )
}

export default TranslationContent
