import { TranslationRevisionDocumentType } from '@digitalworkflow/dwtranslateclient'
import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import { RxDocumentData } from 'rxdb'
import '../../styles/Modals.scss'

interface TranslationRevisionProps {
  content: RxDocumentData<TranslationRevisionDocumentType>[]
  handleCloseTranslationRevision: () => void
}

const TranslationRevision = ({ content, handleCloseTranslationRevision }: TranslationRevisionProps) => {
  const [revisions, setRevisions] = useState<RxDocumentData<TranslationRevisionDocumentType>[]>([])

  useEffect(() => {
    setRevisions(content)
  }, [content])

  return (
    <>
      {content && (
        <div className='modal-container'>
          <div className='revisions-list'>
            {revisions[0]?.fullCode && <div>{revisions[0].fullCode}</div>}
            {revisions.map((revision, id) => (
              <div key={id}>
                <span>V.{revision.version}</span>
                <br />
                <span className='revision-text'>{revision.text}</span>
                <br />
              </div>
            ))}
          </div>
          <div className='text-end'>
            <Button variant='secondary btn-danger' size='sm' onClick={handleCloseTranslationRevision}>
              <i className='fa fa-times me-2' />
              Close
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

export default TranslationRevision
