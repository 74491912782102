import { WindowSettings } from '../types'

/**
 * Provides static methods to manage local storage settings.
 */
export class LocalSettings {
  static readonly DWPORTAL_AUTH_TOKEN = 'dwportal_auth_token'
  static readonly DWPORTAL_USER = 'dwportal_user'
  static readonly DWTHEME = 'dwtheme'
  static readonly DWPORTAL_REDIRECT_URL = 'dwportal_redirect_url'
  static readonly DWPORTAL_ENABLE_TRACE = 'dwportal_enable_trace'
  static readonly BUILDING_SEARCH_CONFIG = 'searchConfig'

  /**
   * Retrieves an item from local storage by key.
   * @param key The key of the item to retrieve.
   * @returns The item's value or null if the key doesn't exist.
   */
  static getItem(key: string): string | null {
    return localStorage.getItem(key)
  }

  /**
   * Sets the value of an item in local storage.
   * @param key The key of the item to set.
   * @param value The value to store.
   */
  static setItem(key: string, value: string): void {
    localStorage.setItem(key, value)
  }

  /**
   * Removes an item from local storage.
   * @param key The key of the item to remove.
   */
  static removeItem(key: string): void {
    localStorage.removeItem(key)
  }

  /**
   * Enables tracing by setting the trace setting in local storage.
   * @param value The trace setting value.
   */
  static setEnableTrace(value: string): void {
    this.setItem(this.DWPORTAL_ENABLE_TRACE, value)
  }

  /**
   * Removes the trace setting from local storage.
   */
  static removeEnableTrace(): void {
    this.removeItem(this.DWPORTAL_ENABLE_TRACE)
  }

  /**
   * Retrieves the trace setting from local storage.
   * @returns The trace setting value.
   */
  static getEnableTrace(): string | null {
    return this.getItem(this.DWPORTAL_ENABLE_TRACE)
  }

  /**
   * Removes the portal user from local storage.
   */
  static removePortalUser(): void {
    this.removeItem(this.DWPORTAL_USER)
  }

  /**
   * Sets the portal user in local storage.
   * @param value The portal user value.
   */
  static setPortalUser(value: string): void {
    this.setItem(this.DWPORTAL_USER, value)
  }

  /**
   * Retrieves the portal user from local storage.
   * @returns The portal user value.
   */
  static getPortalUser(): string | null {
    return this.getItem(this.DWPORTAL_USER)
  }

  /**
   * Removes the portal authentication token from local storage.
   */
  static removePortalAuthToken(): void {
    this.removeItem(this.DWPORTAL_AUTH_TOKEN)
  }

  /**
   * Retrieves the portal authentication token from local storage.
   * @returns The authentication token.
   */
  static getPortalAuthToken(): string | null {
    return this.getItem(this.DWPORTAL_AUTH_TOKEN)
  }

  /**
   * Sets the portal authentication token in local storage.
   * @param value The token value.
   */
  static setPortalAuthToken(value: string): void {
    this.setItem(this.DWPORTAL_AUTH_TOKEN, value)
  }

  /**
   * Sets the portal redirect URL in local storage.
   * @param value The redirect URL value.
   */
  static setPortalRedirectUrl(value: string): void {
    this.setItem(this.DWPORTAL_REDIRECT_URL, value)
  }

  /**
   * Retrieves the portal redirect URL from local storage.
   * @returns The redirect URL.
   */
  static getPortalRedirectUrl(): string | null {
    return this.getItem(this.DWPORTAL_REDIRECT_URL)
  }

  /**
   * Removes the portal redirect URL from local storage.
   */
  static removePortalRedirectUrl(): void {
    this.removeItem(this.DWPORTAL_REDIRECT_URL)
  }

  /**
   * Retrieves the theme setting from local storage.
   * @returns The theme setting value.
   */
  static getTheme(): string | null {
    return this.getItem(this.DWTHEME)
  }

  /**
   * Sets the theme setting in local storage.
   * @param value The theme value.
   */
  static setTheme(value: string): void {
    this.setItem(this.DWTHEME, value)
  }

  /**
   * Retrieves the building search configuration from local storage.
   * @returns The configuration value.
   */
  static getBuildingSearchConfig(): string | null {
    return this.getItem(this.BUILDING_SEARCH_CONFIG)
  }

  /**
   * Sets the building search configuration in local storage.
   * @param value The configuration value.
   */
  static setBuildingSearchConfig(value: string): void {
    this.setItem(this.BUILDING_SEARCH_CONFIG, value)
  }

  /**
   * Removes the building search configuration from local storage.
   */
  static removeBuildingSearchConfig(): void {
    this.removeItem(this.BUILDING_SEARCH_CONFIG)
  }

  /**
   * Retrieves the password for the test account from local storage.
   * @returns The password or an empty string if not set.
   */
  static get testAccountPassword(): string {
    return this.getItem('testAccountPassword') || ''
  }

  /**
   * Sets the password for the test account in local storage.
   * @param newPass The new password to set.
   */
  static set testAccountPassword(newPass: string) {
    this.setItem('testAccountPassword', newPass)
  }

  /**
   * Retrieves the category for the test account from local storage.
   * @returns The category or an empty string if not set.
   */
  static get testAccountCategory(): string {
    return this.getItem('testAccountCategory') || ''
  }

  /**
   * Sets the category for the test account in local storage.
   * @param newCategory The new category to set.
   */
  static set testAccountCategory(newCategory: string) {
    this.setItem('testAccountCategory', newCategory)
  }

  /**
   * Retrieves the display mode for tasks from local storage.
   * @returns The display mode ('list' by default).
   */
  static get taskDisplayMode(): string {
    return this.getItem('taskDisplayMode') || 'list'
  }

  /**
   * Sets the display mode for tasks in local storage.
   * @param value The display mode to set.
   */
  static set taskDisplayMode(value: string) {
    this.setItem('taskDisplayMode', value)
  }

  /**
   * Sets the window settings for tracing in local storage.
   * @param settings The window settings object.
   */
  static set traceWindowSettings(settings: WindowSettings) {
    this.setItem('traceWindowSettings', JSON.stringify(settings))
  }

  /**
   * Retrieves the window settings for tracing from local storage, recalculating if necessary.
   * @returns The window settings object.
   */
  static get traceWindowSettings(): WindowSettings {
    const settingsStr = this.getItem('traceWindowSettings')
    if (settingsStr) {
      const settingsObj = JSON.parse(settingsStr)
      if (settingsObj.screenWidth === window.screen.width && settingsObj.screenHeight === window.screen.height) {
        return settingsObj
      }
    }
    return {
      width: 800,
      height: 600,
      x: 0,
      y: 0,
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
      innerScreenHeight: window.innerHeight,
      innerScreenWidth: window.innerWidth
    }
  }

  /**
   * Determines if the force database refresh flag is set for the current user.
   * @returns true if force refresh is set; otherwise, false.
   */
  static get forceDbRefresh(): boolean {
    const user = JSON.parse(this.getPortalUser() ?? '')
    if (user && user.force_db_refresh) {
      console.log('INIT ForceDBR Refresh is set to true', user)
      return true
    }
    return false
  }
}
