import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthService, AuthResultType } from '@digitalworkflow/dwloginclient'
import { useAuth } from '../../context/AuthContext'
import { LocalSettings, redirectToLogin } from '../../Utils'
import { portalId, projectName } from '../../constants'
import { AppConfig } from '../../config/AppConfig'

/**
 * SetToken handles the OAuth token processing and user authentication.
 * It checks for a token in the URL query params, stores it, and attempts
 * to authenticate the user using this token. On success, it navigates
 * to a specified redirect URL or the dashboard. On failure, it redirects
 * to the login page.
 */
const SetToken = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { setIsAuthenticated, setUser, setAuthTried } = useAuth()

  useEffect(() => {
    const init = async () => {
      // Extract the token from the URL query parameters.
      const params = new URLSearchParams(location.search)
      let authToken = params.get('token')
      console.log(`Loading from http://${document.location.host}?token=` + authToken)

      // Store the token in local storage if present.
      if (authToken) {
        LocalSettings.setPortalAuthToken(authToken)
      } else {
        // Retrieve the token from local storage if not present in the URL.
        authToken = LocalSettings.getPortalAuthToken() ?? ''
      }

      AuthService.setEnvironment(AppConfig.getEnv() as any)

      AuthService.setProjectName(projectName)

      // Hardcoded login ID set for AuthService.
      AuthService.setPortalLoginId(portalId)
      // Authenticate user with the retrieved token.
      const res: AuthResultType = await AppConfig.getAuthServiceInstance().authUserFromToken(authToken)
      console.log('AuthFromToken Result=', res)

      setAuthTried(true)

      if (res && res.is_error === false) {
        // Authentication successful
        setIsAuthenticated(true)

        // Update user context with authenticated user details.
        setUser({
          portals_enabled: res.data.user.portals_enabled,
          username: res.data.user.email,
          email: res.data.user.email
        })

        // Store authenticated user details in local storage.
        LocalSettings.setPortalUser(JSON.stringify(res.data.user))

        // Retrieve the redirect URL from local storage, navigate there, or default to dashboard.
        const redirectUrl = LocalSettings.getPortalRedirectUrl()
        if (redirectUrl) {
          LocalSettings.removePortalRedirectUrl()
          console.log('Redirect to', redirectUrl)
          return navigate(redirectUrl)
        } else {
          console.log('Redirect to relative path')
          return navigate(location.pathname === '/' ? '/translations' : location.pathname)
        }
      }

      // Authentication failed, redirect to the login page.
      console.log('Redirect to Login Portal')
      setIsAuthenticated(false)
      redirectToLogin()
    }

    // Initialize the authentication process.
    init()
  }, [])

  // Visual representation of the page (minimal in this case).
  return <div className='token-page' />
}

export default SetToken
