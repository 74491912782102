import { AppConfig } from '../config/AppConfig'
import { portalId } from '../constants'
import { clearLoginData } from './'

export const redirectToLogin = () => {
  clearLoginData()

  console.log('Redirecting to Login Portal')
  window.location.href = `${AppConfig.getLoginPortalHost()}/login/${portalId}?return=` + window.location.hostname
}
