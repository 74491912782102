import { getRxStorageIndexedDB } from 'rxdb-premium/plugins/storage-indexeddb'
import { CommonHelper, LocalDatabaseManager } from '@digitalworkflow/dwtranslateclient'
import { LocalSettings } from './'
import { AppConfig } from '../config/AppConfig'
import { TableRowCount } from '../types'

/**
 * Periodically checks for database activity and returns once the database is no longer busy.
 * @returns A promise that resolves with true once the database is not busy.
 */
export async function checkForDatabaseBusy(): Promise<boolean> {
  const myTimer = setInterval(() => {
    console.log('Checking for database busy still...')
  }, 100)
  console.log('Checking to see if database is busy...')
  await LocalDatabaseManager.instance().isBusy()
  clearInterval(myTimer)
  return true
}

/**
 * @WARNING not being used
 * Clears all data from the local database.
 */
export const clearDatabase = async (): Promise<void> => {
  try {
    const instance = LocalDatabaseManager.instance()
    await instance.clearDatabase()
  } catch (error) {
    console.error('Error clearing database:', error)
  }
}

/**
 * @WARNING not being used
 * Deletes all IndexedDB databases.
 */
export const deleteDatabase = async (): Promise<void> => {
  try {
    const req = await indexedDB.databases()
    req.forEach(async (item) => {
      await indexedDB.deleteDatabase(item.name as string)
    })
  } catch (error) {
    console.error('Error deleting database:', error)
  }
}

/**
 * Initiates synchronization of the local database with the server.
 * @param setWidth Function to set the synchronization progress as a percentage.
 * @param setSyncing Function to set the syncing state (true/false).
 */
export const syncDatabase = async (setWidth: (value: number) => void): Promise<void> => {
  CommonHelper.setEnvironment(AppConfig.getEnv() as any)
  console.log('🚀 ~ getEnv():', AppConfig.getEnv())

  const instance = LocalDatabaseManager.instance()
  if (instance.db && !instance.db.destroyed) {
    return
  }

  const token = LocalSettings.getPortalAuthToken() ?? ''
  await LocalDatabaseManager.startDb(getRxStorageIndexedDB(), token)

  const totalCollections = instance.getCollectionsWithReplication()
  let collections: string[] = totalCollections.map((collection) => collection.getCollectionName())
  const syncEvents = instance.evDatabaseSyncProgress()

  syncEvents.colletionSyncComplete$.subscribe((collection: string) => {
    if (collection) {
      collections = collections.filter((c) => c !== collection)
      const width = parseFloat((100 - (collections.length / totalCollections.length) * 100).toFixed(2))
      setWidth(width)
    }
  })

  syncEvents.initialPullComplete$.subscribe(async (isComplete: boolean) => {
    if (isComplete) {
      console.log('Initial pull replication is complete.')
      await checkForDatabaseBusy()
      await instance.isBusy()
      setWidth(100)
    }
  })

  syncEvents.error$.subscribe((error: any) => {
    if (error !== null && error !== undefined) {
      console.error('Replication error:', error)
    }
  })

  await instance.isBusy()
}

/**
 * Retrieves the count of items in each collection that has replication enabled.
 * @returns A promise that resolves to an object with collection names as keys and item counts as values.
 */
export const getCollectionsCount = async (): Promise<TableRowCount> => {
  try {
    const instance = LocalDatabaseManager.instance()
    const totalCollections = instance.getCollectionsWithReplication()
    const collections: TableRowCount = {}
    for (let i = 0; i < totalCollections.length; i++) {
      const collection = totalCollections[i]
      const count = await (await (await collection.getRxCollection()).find().exec()).length
      collections[collection.getCollectionName()] = count
    }
    return collections
  } catch (error) {
    console.error('Error getting collections count:', error)
    return {}
  }
}
