/**
 * Converts isActive to true for given index and false for all other indices
 *
 * @param arr - Array of objects with isActive key to loop through and toggle isActive
 * ```
 * [{isActive:true,...rest}]
 * ```
 * @param id - Index to turn isActive to true
 *
 * @returns New Array with changed isActive values
 *
 * @example
 * ```
 * const arr=[{text:'a',isActive:true},{text:'b',isActive:false},{text:'c',isActive:false}]
 * const  newArr= toggleIsActiveForArray(arr,1)
 * console.log(newArr) //prints '[{text:'a',isActive:false},{text:'b',isActive:true},{text:'c',isActive:false}]'
 * ```
 */

export function toggleIsActiveForArray<T>(arr: T[] | null, id: number): T[] {
  const newData: T[] = []
  for (let i = 0; i < (arr?.length ?? 0); i++) {
    if (!arr) return []
    if (i === id) {
      newData?.push({ ...arr[i], isActive: true })
    } else {
      newData?.push({ ...arr[i], isActive: false })
    }
  }
  return newData
}
