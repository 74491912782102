import { RelatedTablesType } from '@digitalworkflow/dwtranslateclient/lib/Models/Translate/TranslateSchema'

export const loginStagingUrl = 'https://stg-login.bgis.com'

export const loginDevUrl = 'https://logindev.dw.protovate.com'

export const loginProdUrl = 'https://stg-login.bgis.com'

export const projectName = 'dwportal'

export const portalId = 'NdE2viPW'

export const RCDockIds = {
  keysSection: 'keysSection',
  keysTab: 'keysTab',
  translationsSection: 'translationsSection',
  translationsTab: 'translationsTab',
  descriptionTab: 'description',
  popupTab: 'popupTab'
}

/**
 * Default custom style of Select Dropdwon
 */
export const defaultSelectStyle = {
  control: () => ({
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    border: '1px solid hsl(0, 0%, 80%)',
    backgroundColor: '#FFFFFF',
    minHeight: 32,
    minWidth: 300,
    fontSize: 14
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: 14,
    color: '#273E61',
    paddingLeft: 12,
    paddingRight: 16,
    paddingTop: 5,
    paddingBottom: 5,
    cursor: 'pointer',
    borderBottom: '1px solid #E5E7EB',
    backgroundColor: state.isSelected ? '#E5E7EB' : state.isFocused ? '#F9FAFB' : '#ffffff',
    '&:active': {
      backgroundColor: 'transparent'
    }
  }),
  placeholder: (defaultStyle: any) => ({
    ...defaultStyle,
    fontSize: 14
  })
}

/**
 * Default custom style of Search key
 */
export const searchkeyStyle = {
  control: () => ({
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    border: '1px solid hsl(0, 0%, 80%)',
    backgroundColor: '#FFFFFF',
    minHeight: 32,
    minWidth: 300,
    fontSize: 14,
    paddingLeft: 35
  }),
  indicatorsContainer: () => ({
    'div:last-child': {
      display: 'none'
    }
  })
}

/**
 * Module type options for filtering the project keys
 */
export const moduleTypeOptions = [
  {
    value: RelatedTablesType.Page,
    label: 'Pages'
  },
  {
    value: RelatedTablesType.Lookup,
    label: 'Lookups'
  },
  {
    value: RelatedTablesType.DataDictionary,
    label: 'Data Dictionaries'
  },
  {
    value: RelatedTablesType.Module,
    label: 'Modules'
  },
  {
    value: RelatedTablesType.ModuleSection,
    label: 'Module Sections'
  },
  {
    value: RelatedTablesType.WorkflowState,
    label: 'Workflow States'
  },
  {
    value: RelatedTablesType.TaskConfig,
    label: 'Task Configs'
  }
]
