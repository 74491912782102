import { AuthService } from '@digitalworkflow/dwloginclient'
import { EnvironmentTypes } from '../types'
import { loginDevUrl, loginProdUrl, loginStagingUrl } from '../constants'

export class AppConfig {
  static authServiceInstance: AuthService

  static setAuthServiceInstance() {
    this.authServiceInstance = AuthService.instance()
  }

  static getAuthServiceInstance() {
    return this.authServiceInstance
  }

  static getEnv(): EnvironmentTypes {
    const _env = (process.env.REACT_APP_ENV || '').toLowerCase().trim()

    if (_env === 'stg') return EnvironmentTypes.STAGING
    else if (_env === 'prod') return EnvironmentTypes.PRODUCTION
    else if (_env === 'local') return EnvironmentTypes.LOCAL
    else return EnvironmentTypes.DEVELOPMENT
  }

  static getLoginPortalHost() {
    const _env = this.getEnv()

    if (_env === EnvironmentTypes.STAGING) return loginStagingUrl
    else if (_env === EnvironmentTypes.PRODUCTION) return loginProdUrl
    else return loginDevUrl
  }
}
