import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import './KeyListItem.scss'

type KeyListItemProps = {
  isActive?: boolean
  id: number
  text: string
  onClick: (id: number) => void
}

const KeyListItem = ({ isActive = false, text = '', id, onClick }: KeyListItemProps) => {
  const renderTooltip = (text: string = '') => {
    return (
      <Tooltip id='tooltip'>
        <span className='tooltip-text'>{text}</span>
      </Tooltip>
    )
  }

  return (
    <OverlayTrigger placement='right' overlay={renderTooltip(text)}>
      <div className={`key-list-item ${isActive ? 'active' : ''}`} onClick={() => onClick(id)}>
        <span className='item-text'>{text}</span>
      </div>
    </OverlayTrigger>
  )
}

export default KeyListItem
