import React, { useRef, useContext, useCallback, useState } from 'react'
import { SyncContext } from '../../context/SyncContext' // Path to your SyncContext
import { DockLayout, RCDockLayout } from '@digitalworkflow/dwreactcommon'
import { RcGroup } from '../../components/RcGroup'
import SyncModal from '../../components/SyncModal'
import Filters from '../../components/Translation/Filters'
import TranslationContent from '../../components/Translation/TranslationContent'
import { RcDockConfig } from '../../config/RcDockConfig'
import EditTranslation from '../../components/Translation/EditTranslation'
import { RCDockIds } from '../../constants'
import { FilterOption, IKey, TranslationVariant } from '../../types'
import 'bootstrap/dist/css/bootstrap.min.css'
import KeyList from '../../components/Translation/KeyList'
import { TranslateCollection, TranslationRevisionCollection } from '@digitalworkflow/dwtranslateclient'
import { RelatedTablesType } from '@digitalworkflow/dwtranslateclient/lib/Models/Translate/TranslateSchema'
import TranslationRevision from '../../components/Translation/TranslationRevision'

function Translations() {
  const [keys, setKeys] = useState<IKey[]>([])
  const currentRelatedTables = useRef<RelatedTablesType[]>([])
  const currentLanguage = useRef('')
  const currentKey = useRef<string>('')
  const dockLayoutRef = useRef<DockLayout | null>(null)

  const { isSyncing, syncProgress } = useContext(SyncContext)

  async function getKeys(currentRelatedTables: RelatedTablesType[]) {
    const translates = await TranslateCollection.findAllForRelatedTable(currentRelatedTables)

    const newArray: IKey[] = translates
      .filter(
        (key) =>
          key.variants !== undefined &&
          key.variants.findIndex((v) => v.fullCode.toLowerCase() === currentLanguage.current) >= 0
      )
      .map((key) => key._data)

    let activeKeyId = ''
    if (newArray.length) {
      newArray.forEach((key) => (key.isActive = false))
      if (currentKey.current.length) {
        newArray.forEach((key) => {
          if (key.id === currentKey.current) {
            key.isActive = true
            activeKeyId = key.id
          }
        })
      } else {
        newArray[0].isActive = true
        activeKeyId = newArray[0]?.id ?? ''
      }
    }

    activeKeyId.length && handleKeySelect(activeKeyId)
    setKeys(newArray)
    return newArray
  }

  async function getTranslationContent(id: string) {
    const translate = await TranslateCollection.findOneById(id)
    if (translate && translate.data.variants?.length) {
      getAndUpdateTranslationsTab(translate.data.variants)
    } else if (!id.length || !translate || !translate.data.variants?.length) {
      getAndUpdateTranslationsTab([])
    }
  }

  function closeModal() {
    RcDockConfig.removeRCTab(dockLayoutRef, RCDockIds.popupTab)
  }

  function handleCancelEditTranslation() {
    closeModal()
  }

  async function handleUpdateEditTranslation(updatedValue: string, variant: TranslationVariant) {
    await TranslateCollection.addTranslation(
      variant.shortCode,
      variant.fullCode,
      updatedValue,
      undefined,
      currentKey.current
    )
    const keys = await getKeys(currentRelatedTables.current)
    getAndUpdateKeysTab(keys)
    closeModal()
  }

  async function handleLanguageChange(language: FilterOption) {
    if (language.value?.length) {
      currentLanguage.current = language.value as string
    }
  }

  function handleKeySelect(keyId: string) {
    getTranslationContent(keyId)
    currentKey.current = keyId
  }

  async function handleKeysReturned(keys: IKey[]) {
    currentKey.current = keys.find((k) => k.isActive === true)?.id ?? keys[0]?.id ?? ''
    getAndUpdateKeysTab(keys)
    getTranslationContent(currentKey.current)
  }

  function getAndUpdateTranslationsTab(translations: TranslationVariant[]) {
    const newTab = {
      id: RCDockIds.translationsTab,
      title: 'TRANSLATIONS',
      content: (
        <TranslationContent
          handleEditTranslation={handleEditTranslation}
          handleRevision={handleRevision}
          TranslationContentList={translations}
        />
      ),
      group: 'close-all'
    }

    dockLayoutRef.current?.updateTab(RCDockIds.translationsTab, newTab)
  }

  function getAndUpdateKeysTab(keys: IKey[]) {
    const newTab = {
      id: RCDockIds.keysTab,
      title: 'KEYS',
      content: (
        <KeyList
          keys={[...keys]}
          selectedLanguageFullCode={currentLanguage.current}
          handleKeySelect={(keyId: string) => handleKeySelect(keyId)}
        />
      ),
      group: 'close-all'
    }

    dockLayoutRef.current?.updateTab(RCDockIds.keysTab, newTab)
  }

  const handleEditTranslation = useCallback((content: TranslationVariant) => {
    closeModal() // Close the previous edit tab first
    RcDockConfig.addFloatBox(
      dockLayoutRef,
      RCDockIds.popupTab,
      RcDockConfig.getTab(
        RCDockIds.popupTab,
        'Edit Translation',
        dockLayoutRef,
        () => (
          <EditTranslation
            content={content}
            handleCancelEditTranslation={() => handleCancelEditTranslation()}
            handleUpdateEditTranslation={(updatedValue: string, variant: TranslationVariant) =>
              handleUpdateEditTranslation(updatedValue, variant)
            }
          />
        ),
        350,
        (document.getElementsByClassName('dock-container')[0]?.clientWidth ?? 800) / 2 - 250,
        500,
        30
      )
    )
  }, [])

  const handleRevision = useCallback(async (content: TranslationVariant) => {
    closeModal() // Close the previous edit tab first
    const revisions = await TranslationRevisionCollection.findRevisionsByTranslation(currentKey.current)
    const currentRevisions = revisions.filter((rev) => rev.fullCode === content.fullCode).map((rev) => rev._data)
    RcDockConfig.addFloatBox(
      dockLayoutRef,
      RCDockIds.popupTab,
      RcDockConfig.getTab(
        RCDockIds.popupTab,
        'Revisions',
        dockLayoutRef,
        () => <TranslationRevision content={currentRevisions} handleCloseTranslationRevision={() => closeModal()} />,
        350,
        (document.getElementsByClassName('dock-container')[0]?.clientWidth ?? 800) / 2 - 250,
        500,
        30
      )
    )
  }, [])

  const defaultLayout: any = {
    dockbox: {
      mode: 'vertical',
      children: [
        {
          size: 1,
          tabs: [
            {
              id: RCDockIds.descriptionTab,
              title: 'TRANSLATIONS',
              content: (
                <Filters
                  handleLanguageChange={(option: FilterOption) => handleLanguageChange(option)}
                  handleKeysReturned={(keys: IKey[]) => handleKeysReturned(keys)}
                />
              ),
              group: 'headless',
              minHeight: 145
            }
          ]
        },
        {
          size: 20,
          mode: 'horizontal',
          children: [
            {
              size: 1,
              tabs: [
                {
                  id: RCDockIds.keysTab,
                  title: 'KEYS',
                  content: (
                    <KeyList
                      keys={keys}
                      selectedLanguageFullCode={currentLanguage.current}
                      handleKeySelect={(keyId: string) => handleKeySelect(keyId)}
                    />
                  ),
                  group: 'close-all'
                }
              ]
            },
            {
              size: 6,
              id: RCDockIds.translationsSection,
              tabs: [
                {
                  id: RCDockIds.translationsTab,
                  title: 'TRANSLATIONS',
                  content: (
                    <TranslationContent
                      handleEditTranslation={handleEditTranslation}
                      handleRevision={handleRevision}
                      TranslationContentList={[]}
                    />
                  ),
                  group: 'close-all'
                }
              ]
            }
          ]
        }
      ]
    }
  }

  return (
    <>
      {/* <div style={{ height: '100vh' }}> */}
      {!isSyncing && <RCDockLayout dockLayoutRef={dockLayoutRef} groups={RcGroup} defaultLayout={defaultLayout} />}
      <SyncModal width={syncProgress} isOpen={isSyncing} />
      {/* </div> */}
    </>
  )
}

export default Translations
