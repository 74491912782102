import React, { createContext, useState, useEffect, PropsWithChildren, FC } from 'react'
import { syncDatabase, checkForDatabaseBusy } from '../Utils'

/**
 * Interface defining the shape of the synchronization context's value.
 */
interface SyncContextType {
  isSyncing: boolean // Indicates if a synchronization is currently in progress.
  syncProgress: number // Represents the progress of the current synchronization as a percentage.
  startSync: () => void // Function to initiate the synchronization process.
}

/**
 * Context for managing database synchronization across the React application.
 */
const SyncContext = createContext<SyncContextType>({
  isSyncing: false,
  syncProgress: 0,
  startSync: () => {}
})

/**
 * Provides a SyncContext provider that manages and provides state related to database synchronization.
 *
 * @param props The children elements wrapped by the provider.
 * @returns A React component that provides synchronization state to its children.
 */
const SyncProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [isSyncing, setIsSyncing] = useState(true)
  const [syncProgress, setSyncProgress] = useState(0)

  /**
   * Starts the database synchronization process and updates the context's state.
   */
  const startSync = async () => {
    setSyncProgress(0)

    await syncDatabase(setSyncProgress)
    await checkForDatabaseBusy() // Ensure database is ready for queries
    setIsSyncing(false)
  }

  useEffect(() => {
    // Optional: attempt an initial sync on context creation to ensure data freshness.
    startSync()
  }, [])

  return <SyncContext.Provider value={{ isSyncing, syncProgress, startSync }}>{children}</SyncContext.Provider>
}

export { SyncContext, SyncProvider }
