import { TranslateDocumentType } from '@digitalworkflow/dwtranslateclient'

/* eslint-disable no-unused-vars */
export type TableRowCount = {
  [index: string]: number
}

/**
 * Interface of filter option
 */
export interface FilterOption {
  value: string
  label: string
}

/**
 * Interface of language object
 */
export interface Language {
  language: string
  full_code: string
  short_code: string
  is_active: boolean
}

export interface TranslationVariant {
  shortCode: string
  fullCode: string
  text: string
  version: number
}

export enum EnvironmentTypes {
  PRODUCTION = 'production',
  STAGING = 'staging',
  DEVELOPMENT = 'development',
  LOCAL = 'local'
}

export interface IKey extends Partial<TranslateDocumentType> {
  isActive?: boolean
}

export type WindowSettings = {
  width: number // window width
  height: number // window height
  x: number // position x
  y: number // position y
  screenWidth: number // display screen width
  screenHeight: number // display screen height
  innerScreenHeight: number
  innerScreenWidth: number
}
