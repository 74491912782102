import React, { ChangeEvent, useEffect, useState } from 'react'
import { TranslationVariant } from '../../types'
import Button from 'react-bootstrap/Button'
import '../../styles/Modals.scss'

interface EditTranslationProps {
  content: TranslationVariant
  handleCancelEditTranslation: () => void
  handleUpdateEditTranslation: (updatedValue: string, variant: TranslationVariant) => void
}

const EditTranslation = ({
  content,
  handleCancelEditTranslation,
  handleUpdateEditTranslation
}: EditTranslationProps) => {
  const [text, setText] = useState('')

  useEffect(() => {
    setText(content.text)
  }, [content])

  return (
    <>
      {content && (
        <div className='modal-container'>
          <div>{content.fullCode}</div>
          <textarea
            className='input-key'
            spellCheck='false'
            value={text}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setText(e.target.value)}
          />
          <div className='text-end'>
            <Button
              variant='primary'
              size='sm'
              className='me-2'
              onClick={() => handleUpdateEditTranslation(text, content)}
            >
              <i className='fa fa-cloud-arrow-up me-2' />
              Update
            </Button>
            <Button variant='secondary btn-danger' size='sm' onClick={handleCancelEditTranslation}>
              <i className='fa fa-times me-2' />
              Cancel
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

export default EditTranslation
